/* styles.css */
.adminMenu .ant-menu-item {
    padding: 10px 30px !important;
    width: auto !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}


.adminMenu .ant-menu-item-selected {
    background-color: #4f1597 !important;
    border-radius: 25px !important;
    padding: 10px 30px !important;
    width: auto !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.adminMenu .ant-menu-item-selected span,
.adminMenu .ant-menu-item-selected a {
    color: white !important;
}

.adminMenu .ant-menu-item:hover {
    background-color: #4f1597 !important;
    border-radius: 25px !important;
    padding: 10px 30px !important;
    width: auto !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.adminMenu .ant-menu-item:hover span,
.adminMenu .ant-menu-item:hover a {
    color: white !important;
}
