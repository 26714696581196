.ant-layout-content{
  padding: 24px 24px 0px 24px;
  height: calc(100vh - 130px);
}

.accountContent {
    padding: 24px 24px 0px 24px;
    height: calc(100vh - 100px);
}

.adminContent{
    padding: 24px 24px 0px 24px;
    height: calc(100vh - 100px);
}

.menu-item-search-bar {
  margin-left: auto;
  width: 22%;
  min-width: 150px;
}

.menu-item-search-bar:hover {
  background-color: #170041 !important;
}

@media only screen and (max-width: 930px) {
  .menu-item-search-bar {
    display: none !important;
  }
}
