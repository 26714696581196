.barWidgetContainer .echarts-for-react div {
    cursor: default !important;
}

.brandFilterContainer .MuiButton-text{
    color: #4f1597 !important;
    text-decoration: none;
}

.brandFilterContainer .MuiButton-text:hover {
    text-decoration: underline;
    background-color: transparent;
}

.brandFilterContainer .MuiLink-root{
    color: #4f1597 !important;
    text-decoration: none;
}

.brandFilterContainer .MuiLink-root:hover {
    text-decoration: underline;
    background-color: transparent;
}


.brandFlexPrice .progressbar div {
    width: 100% !important;
}
