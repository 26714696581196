.app-loading {
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  top: 40%;
}

.accountStart .ant-layout {
    background-color: white;
}

@primary-color: #4f1597;@layout-header-background: #170041;@font-family: Poppins;