.signup {
  padding-top: 10vh;
  height: 100vh;
  background-color: #16003d;

  .ant-btn {
    margin-right: 5px;
  }
}
.required {
  color: grey;
  font-style:inherit;
}

.box {
  display:flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center
}

.password-strength-meter {
  height: 0.3rem;
  background-color: lightgrey;
  border-radius: 3px;
  margin: .5rem 0
}
.error-pwd {
  color: #FF0000;
  font-style: italic;
}