.info-window {
    background-color: #4F1597 !important;
    padding: 15px !important;
    border-radius: 16px !important;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3) !important;
    max-width: 400px !important;
    pointer-events: auto !important;
    position: absolute !important;
    transform: translate(-50%, -100%) !important;
}

.deck-tooltip {
    background-color: #4F1597 !important;
    padding: 15px !important;
    border-radius: 16px !important;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3) !important;
    pointer-events: auto !important;
}


.deck-tooltip .info-content,
.info-window .info-content {
    font-size: 16px;
    color: #F9F9F9;
}

.info-window .html-text {
    font-size: 16px;
    color: #F9F9F9;
    text-decoration: none;
}

.info-window .html-text:hover {
    font-size: 16px;
    color:  rgb(255, 255, 255, 0.5);
    text-decoration: underline;
}

.info-window .button{
    background-color: #fff;
    color: #4F1597;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;

    display: block;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    width: 100%;
    max-width: 100px;
}

.info-window .button:hover {
    background-color: rgb(255, 255, 255, 0.5);
}

.info-window .close-button {
    position: absolute;
    top: 5px;
    right: 8px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #999;
}

.info-window .close-button:hover {
    color: #666;
}
