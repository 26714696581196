.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: transparent !important;
}

.ant-menu-dark.ant-menu-horizontal .ant-menu-item .ant-menu-title-content >  a:hover {
    color: #4f1597 !important;
    background-color: white !important;
    border-radius: 25px !important;
    padding: 10px 30px !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.ant-menu-light.ant-menu-horizontal  > .ant-menu-item:hover,
.ant-menu-light.ant-menu-horizontal  > .ant-menu-submenu:hover {
  text-decoration: underline !important;
  text-decoration-color: #4f1597 !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 6px !important;
}

.ant-menu-dark .ant-menu-sub .ant-menu-title-content >  a:hover {
    color: #4f1597 !important;
    background-color: white !important;
    border-radius: 25px !important;
    padding: 10px 30px !important;
}
