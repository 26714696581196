.currencyItem.Mui-selected {
    background-color: #4F1597 !important;
    color: #F9F9F9D9 !important;
    font-weight: bold !important;
}

.currencyItem {
    padding: 10px 25px;
    font-size: 15px !important;
    color: #2F2F2FD9 !important;
}

#currency-select{
    padding: 10px 25px !important;
    font-weight: bold !important;
    font-size: 15px !important;
    color: #2F2F2F !important;
}

.currencySelector .MuiOutlinedInput-notchedOutline {
    border-color: rgba(44, 48, 50, 0.25) !important;
}
